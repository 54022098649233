/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: border-box;
  padding: 0 2rem; }

::selection {
  background-color: #54aa01;
  color: #fff; }

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: #596a74; }

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  margin-bottom: 6rem; }
  .heading-primary--main {
    display: block;
    font-size: 4rem;
    font-weight: 400;
    letter-spacing: 1.5rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */ }
    @media only screen and (max-width: 37.5em) {
      .heading-primary--main {
        letter-spacing: 0.6rem;
        font-family: 5rem; } }
  .heading-primary--sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.75rem;
    animation: moveInRight 1s ease-out; }
    @media only screen and (max-width: 37.5em) {
      .heading-primary--sub {
        letter-spacing: .5rem; } }

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #54aa01, #28b485);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .heading-secondary {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-secondary {
      font-size: 2.5rem; } }

.heading-secondary-white {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #fff, #fff);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .heading-secondary-white {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-secondary-white {
      font-size: 2.5rem; } }

.heading-secondary-big {
  font-size: 4rem;
  text-transform: uppercase;
  font-weight: 900;
  display: inline-block;
  background-image: linear-gradient(to right, #54aa01, #28b485);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .heading-secondary-big {
      font-size: 3.5rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-secondary-big {
      font-size: 3rem; } }

.heading-section-2 {
  font-size: 3.5rem;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #54aa01, #28b485);
  -webkit-background-clip: text;
  color: white;
  letter-spacing: .2rem;
  transition: all .2s; }
  .heading-section-2--sub {
    font-size: 1.7rem;
    color: white;
    letter-spacing: .2rem; }
  @media only screen and (max-width: 56.25em) {
    .heading-section-2 {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-section-2 {
      font-size: 2.5rem; } }

.heading-tertiary {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase; }

.heading-tertiary-big {
  font-size: 2.5rem;
  font-weight: 900; }

.heading-quaternary {
  font-size: 4rem;
  font-weight: 900;
  display: inline-block;
  background-image: linear-gradient(to right, #54aa01, #28b485);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .heading-quaternary {
      font-size: 3.5rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-quaternary {
      font-size: 3rem; } }

.paragraph {
  font-size: 1.6rem; }
  .paragraph:not(:last-child) {
    margin-bottom: 3rem; }

.paragraph-1 {
  font-size: 2rem; }
  .paragraph-1:not(:last-child) {
    margin-bottom: 3rem; }

.paragraph-white {
  font-size: 2.8rem;
  color: white; }
  @media only screen and (max-width: 56.25em) {
    .paragraph-white {
      font-size: 2.3rem; } }
  @media only screen and (max-width: 37.5em) {
    .paragraph-white {
      font-size: 1.8rem; } }

.u-center-text {
  text-align: center !important; }

.u-margin-top-small {
  margin-top: 1.5rem !important; }

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }
  @media only screen and (max-width: 56.25em) {
    .u-margin-bottom-medium {
      margin-bottom: 3rem !important; } }

.u-margin-top-medium {
  margin-top: 4rem !important; }
  @media only screen and (max-width: 56.25em) {
    .u-margin-top-medium {
      margin-top: 3rem !important; } }

.u-margin-bottom-big {
  margin-bottom: 8rem !important; }
  @media only screen and (max-width: 56.25em) {
    .u-margin-bottom-big {
      margin-bottom: 5rem !important; } }

.u-margin-top-big {
  margin-top: 8rem !important; }

.u-margin-top-huge {
  margin-top: 10rem !important; }

.u-text-color-orange {
  color: #F68505; }

.u-text-color-ash {
  color: #596a74; }

.u-text-color-primary {
  color: #54aa01; }

.u-text-color-sky-blue {
  color: #7FBBC6; }

.u-h3-text-format {
  letter-spacing: .1rem;
  color: #000;
  font-size: 2rem; }

.u-p-text-format {
  color: #000;
  letter-spacing: .1rem;
  font-size: 1.8rem; }

.u-btn-text {
  color: #fff !important; }

.btn, .btn:link, .btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  position: relative;
  font-size: 1.6rem;
  border: none;
  cursor: pointer; }

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  .btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }

.btn:active, .btn:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.btn--white {
  background-color: #fff;
  color: #596a74; }
  .btn--white::after {
    background-color: #fff; }

.btn--green {
  background-color: #54aa01;
  color: #fff; }
  .btn--green::after {
    background-color: #54aa01; }

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s; }

.btn--animated {
  animation: moveInBottom .5s ease-out .75s;
  animation-fill-mode: backwards; }

.btn-text:link, .btn-text:visited {
  font-size: 1.6rem;
  color: #54aa01;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #54aa01;
  padding: 3px;
  transition: all .2s; }

.btn-text:hover {
  background-color: #54aa01;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); }

.btn-text:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0); }

.composition {
  position: relative; }
  .composition__photo {
    width: 55%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    transition: all .2s;
    outline-offset: 2rem; }
    @media only screen and (max-width: 56.25em) {
      .composition__photo {
        float: left;
        position: relative;
        width: 33.33333333%;
        box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2); } }
    .composition__photo--p1 {
      left: 0;
      top: -2rem; }
      @media only screen and (max-width: 56.25em) {
        .composition__photo--p1 {
          top: 0;
          transform: scale(1.2); } }
    .composition__photo--p2 {
      right: 0;
      top: 2rem; }
      @media only screen and (max-width: 56.25em) {
        .composition__photo--p2 {
          top: -1rem;
          transform: scale(1.3);
          z-index: 100; } }
    .composition__photo--p3 {
      left: 20%;
      top: 10rem; }
      @media only screen and (max-width: 56.25em) {
        .composition__photo--p3 {
          top: 1rem;
          left: 0;
          transform: scale(1.1); } }
    .composition__photo:hover {
      transform: scale(1.05) translateY(-0.5rem);
      box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
      z-index: 20; }
  .composition:hover .composition__photo:not(:hover) {
    transform: scale(0.95); }

.feature-box {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  min-height: 370px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
  cursor: pointer; }
  @media only screen and (max-width: 56.25em) {
    .feature-box {
      padding: 2rem;
      min-height: initial; } }
  .feature-box__heading {
    font-weight: bold;
    color: #54aa01 !important;
    font-size: 1.6rem; }
  .feature-box__icon {
    font-size: 6rem;
    margin-bottom: 0.5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .feature-box__icon {
        margin-bottom: 0; } }
  .feature-box:hover {
    transform: translateY(-1.5rem) scale(1.08); }
  .feature-box .row > .col-1-of-4 > a,
  .feature-box a:visited,
  .feature-box a:active {
    text-decoration: none; }

a {
  text-decoration: none;
  color: inherit; }

a:hover {
  color: inherit; }

.story {
  width: 75%;
  margin: 0 auto;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  padding: 6rem;
  padding-left: 9rem;
  font-size: 1.6rem;
  transform: skewX(-12deg); }
  @media only screen and (max-width: 56.25em) {
    .story {
      width: 100%;
      padding: 4rem;
      padding-left: 7rem; } }
  @media only screen and (max-width: 37.5em) {
    .story {
      transform: skewX(0); } }
  .story__shape {
    width: 15rem;
    height: 15rem;
    float: left;
    transform: translateX(-3rem) skewX(12deg);
    position: relative;
    overflow: hidden;
    border-radius: 50%; }
    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      .story__shape {
        -webkit-clip-path: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        -webkit-shape-outside: circle(50% at 50% 50%);
        shape-outside: circle(50% at 50% 50%);
        border-radius: none; } }
    @media only screen and (max-width: 37.5em) {
      .story__shape {
        transform: translateX(-3rem) skewX(0); } }
  .story__img {
    height: 100%;
    transform: translateX(-4rem) scale(1.4);
    backface-visibility: hidden;
    transition: all .5s; }
  .story__text {
    transform: skewX(12deg); }
    @media only screen and (max-width: 37.5em) {
      .story__text {
        transform: skewX(0); } }
  .story__caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.7rem;
    text-align: center;
    opacity: 0;
    transition: all .5s;
    backface-visibility: hidden; }
  .story:hover .story__caption {
    opacity: 1;
    transform: translate(-50%, -50%); }
  .story:hover .story__img {
    transform: translateX(-4rem) scale(1);
    filter: blur(3px) brightness(80%); }

.story-page__border {
  border-bottom: 1.2rem solid #54aa01;
  margin-bottom: 7rem; }

.the-card__container {
  flex: 1 1 30%;
  max-width: 61rem; }
  @media only screen and (max-width: 75em) {
    .the-card__container {
      max-width: 50rem; } }
  @media only screen and (max-width: 56.25em) {
    .the-card__container {
      flex: 1 1 100%;
      width: 100%; } }
  @media only screen and (max-width: 37.5em) {
    .the-card__container {
      width: 96%; } }

.the-card {
  position: relative;
  height: 41.7rem;
  color: #fff;
  cursor: pointer;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 56.25em) {
    .the-card {
      width: inherit;
      margin: auto;
      margin-bottom: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .the-card {
      width: inherit;
      margin: auto;
      margin-bottom: 3rem; } }
  @media only screen and (max-width: 25em) {
    .the-card {
      height: 28rem; } }
  .the-card__main {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 5px; }
    .the-card__main-border {
      border-bottom: 0.3rem solid #ff7730; }
    .the-card__main-text {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
    .the-card__main-title {
      font-size: 2rem;
      font-weight: 600; }
    .the-card__main-icon {
      font-size: 2rem; }
  .the-card__alt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background-color: #54aa01;
    color: #fff;
    padding: 2rem;
    visibility: hidden;
    border-radius: 5px;
    transition: 0.2s ease-in; }
    .the-card__alt-text {
      font-size: 1.7rem;
      margin-bottom: 1.5rem;
      opacity: 0;
      transition: 0.2s ease-in; }
    .the-card__alt-link {
      opacity: 0;
      transition: 0.2s ease-in; }
    .the-card__alt-link > a,
    .the-card__alt a:visited,
    .the-card__alt a:active {
      color: #fff;
      font-size: 1.8rem;
      font-weight: 600;
      text-decoration: none; }
    .the-card__alt-link > a:hover {
      text-decoration: underline; }
  .the-card__img-1 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../public/img/bpl-8.jpg); }
  .the-card__img-2 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../public/img/bpl-11.jpg); }
  .the-card__img-3 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../public/img/bpl-10.jpg); }
  .the-card__img-4 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../public/img/bpl-9.jpg); }
  .the-card__img-5 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../public/img/bpl-15.jpg); }
  @media only screen and (min-width: 25em) {
    .the-card:hover > .the-card__alt {
      visibility: visible;
      height: 100%; }
      .the-card:hover > .the-card__alt .the-card__alt-text {
        opacity: 1; }
      .the-card:hover > .the-card__alt .the-card__alt-link {
        opacity: 1; } }

.the-card__mr {
  margin-right: 5rem; }
  @media only screen and (max-width: 56.25em) {
    .the-card__mr {
      margin-right: 0; } }

.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s; }
  @supports (-webkit-backdrop-filter: blur(3px)) or (backdrop-filter: blur(3px)) {
    .popup {
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      background-color: rgba(0, 0, 0, 0.4); } }

.contact-form__container {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0.25);
  font-size: 2rem;
  background-color: #54aa01;
  color: #fff;
  min-width: 35%;
  box-shadow: 0 1rem 4rem 1rem rgba(0, 0, 0, 0.4);
  padding: 4rem 1rem;
  transition: all .5s; }
  .contact-form__container-close__icon {
    position: absolute;
    top: 3rem;
    right: 2rem;
    font-size: 3rem;
    color: #fff;
    transition: .1 ease-in; }
    .contact-form__container-close__icon:hover {
      cursor: pointer; }
    .contact-form__container-close__icon:active {
      transform: scale(0.7); }
  .contact-form__container h4 {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: .4rem; }

.contact-form {
  background-color: #fff;
  color: #54aa01;
  font-weight: 700;
  padding: .5rem 1rem;
  border-radius: 10px;
  opacity: 0;
  transition: all .5s .2s; }
  .contact-form__row {
    display: flex;
    flex-direction: column;
    margin: 3rem 3rem; }
    .contact-form__row input {
      border: none;
      border-bottom: 2px solid #54aa01;
      height: 4rem;
      font-size: 1.7rem;
      outline: none; }
    .contact-form__row textarea {
      border: none;
      border-bottom: 2px solid #54aa01;
      font-size: 1.7rem;
      outline: none;
      resize: none; }
    .contact-form__row p {
      font-size: 1.4rem;
      color: red !important; }
    .contact-form__row button {
      text-align: center;
      width: 8rem;
      height: 3.5rem;
      border-radius: 5px;
      border-color: #fff;
      cursor: pointer;
      background-color: #54aa01;
      color: #fff; }
      .contact-form__row button:hover, .contact-form__row button:active {
        background-color: #fff;
        color: #54aa01;
        font-weight: 700;
        border-color: #54aa01; }
    .contact-form__row:last-child {
      align-items: center; }

.contact-button {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  padding: 2rem 2rem 2rem 2rem;
  border-radius: 50%;
  background-color: #54aa01;
  box-shadow: 0.3rem 0.4rem 0.3rem 0.3rem rgba(0, 0, 0, 0.4);
  transition: .3s ease-in; }
  .contact-button__icon {
    font-size: 3rem;
    color: #fff;
    padding-top: .5rem; }
  .contact-button:hover {
    cursor: pointer;
    background-color: #F68505;
    transform: scale(1.15); }
  .contact-button:active {
    background-color: #7FBBC6;
    transform: scale(0.5); }

.show-form {
  visibility: visible;
  opacity: 1; }
  .show-form .contact-form__container {
    transform: translateY(-50%) translateX(-50%) scale(1);
    opacity: 1; }
    .show-form .contact-form__container .contact-form {
      opacity: 1; }
    @media only screen and (max-width: 56.25em) {
      .show-form .contact-form__container {
        min-width: 55%; } }
    @media only screen and (max-width: 37.5em) {
      .show-form .contact-form__container {
        min-width: 100%;
        min-height: 100%; } }

.submit-feedback {
  font-size: 2rem !important; }
  @media only screen and (max-width: 56.25em) {
    .submit-feedback {
      font-size: 1.6rem !important; } }

.footer {
  background-color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  color: #54aa01;
  padding-bottom: 1rem; }
  .footer__row {
    margin-top: 10rem;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 8rem; }
    @media only screen and (max-width: 37.5em) {
      .footer__row {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 5%; }
        .footer__row > .col-1-of-3:not(:last-child) {
          margin-bottom: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .footer__row-item {
      order: -1;
      align-self: center;
      margin-bottom: 4rem; } }
  .footer__logo-box {
    margin-bottom: 3rem; }
  .footer__logo {
    height: 13rem; }
  .footer__header-primary {
    font-size: 2rem;
    color: #F68505;
    margin-bottom: 1.1rem; }
  .footer__header {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #F68505; }
  .footer__list {
    list-style: none; }
  .footer__item:not(:last-child) {
    margin-bottom: 1.4rem; }
  .footer__link:link, .footer__link:visited {
    color: #54aa01;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block; }
  .footer__link:hover, .footer__link:active {
    color: #7FBBC6;
    text-decoration: underline; }
  .footer__icons {
    font-size: 3.5rem; }
  .footer__icons a:link {
    text-decoration: none;
    color: #54aa01; }
  .footer__icon {
    margin-right: 1.4rem; }
  .footer__copyright {
    margin-top: -4rem; }

address a:link {
  color: #54aa01; }

.row {
  max-width: 114rem;
  margin: 0 auto; }
  .row:not(:last-child) {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .row:not(:last-child) {
        margin-bottom: 6rem; } }
  @media only screen and (max-width: 56.25em) {
    .row {
      max-width: 50rem;
      padding: 0 3rem; } }
  .row::after {
    content: "";
    display: table;
    clear: both; }
  .row [class^="col-"] {
    float: left; }
    .row [class^="col-"]:not(:last-child) {
      margin-right: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .row [class^="col-"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .row [class^="col-"] {
        width: 100% !important; } }
  .row .col-1-of-2 {
    width: calc((100% - 6rem) / 2); }
  .row .col-1-of-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .row .col-2-of-3 {
    width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem); }
  .row .col-1-of-4 {
    width: calc((100% - 3 * 6rem) / 4); }
  .row .col-2-of-4 {
    width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem); }
  .row .col-3-of-4 {
    width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem); }

.topheader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; }

.topheader-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  height: 70px;
  box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.4); }

@media only screen and (max-width: 56.25em) {
  .topheader-nav {
    display: none; } }

.topheader-nav__active {
  border-bottom: 5px solid #54aa01; }

.topheader-nav a {
  font-size: 2rem;
  margin-left: 3rem; }

.topheader-nav a:hover {
  color: #54aa01; }

.app-main {
  padding-top: 10rem; }

.header {
  height: 85vh;
  background-image: linear-gradient(to left bottom, rgba(84, 170, 1, 0.6), rgba(40, 180, 133, 0.8)), url(../public/img/home.jpg);
  background-size: cover;
  background-position: top;
  position: relative; }
  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    .header {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
      height: 95vh; } }
  @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
    .header {
      background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.8), rgba(40, 180, 133, 0.8)), url(../public/img/home.jpg); } }
  @media only screen and (max-width: 37.5em) {
    .header {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%); } }
  .header__logo-box {
    position: absolute;
    top: 4rem;
    left: 4rem; }
  .header__logo {
    height: 13rem; }
    @media only screen and (max-width: 37.5em) {
      .header__logo {
        height: 6rem; } }
  .header__text-box {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }

.navigation__checkbox {
  display: none; }

.navigation__button {
  display: none;
  background-color: #f7f7f7;
  height: 6rem;
  width: 6rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer; }
  @media only screen and (max-width: 56.25em) {
    .navigation__button {
      display: block; } }

.navigation__background {
  display: none;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  background-image: radial-gradient(#54aa01, #54aa01);
  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1); }
  @media only screen and (max-width: 56.25em) {
    .navigation__background {
      display: block; } }

.navigation__nav {
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  opacity: 0;
  width: 0;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  @media only screen and (max-width: 56.25em) {
    .navigation__nav {
      display: block; } }

.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
  width: 100%; }

.navigation__item {
  margin: 1rem; }

.navigation__link:link, .navigation__link:visited {
  display: inline-block;
  font-size: 3rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%);
  background-size: 220%;
  transition: all .4s; }
  .navigation__link:link span, .navigation__link:visited span {
    margin-right: 1.5rem;
    display: inline-block; }

.navigation__link:hover, .navigation__link:active {
  background-position: 100%;
  color: #54aa01;
  transform: translateX(1rem); }

.navigation__checkbox:not(:checked) ~ .navigation__nav {
  visibility: hidden; }

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(80); }

.navigation__checkbox:checked ~ .navigation__nav {
  opacity: 1;
  width: 100%; }

.navigation__icon {
  position: relative;
  margin-top: 3rem; }
  .navigation__icon, .navigation__icon::before, .navigation__icon::after {
    width: 3rem;
    height: 2px;
    background-color: #333;
    display: inline-block; }
  .navigation__icon::before, .navigation__icon::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all .2s; }
  .navigation__icon::before {
    top: -.8rem; }
  .navigation__icon::after {
    top: .8rem; }

.navigation__button:hover .navigation__icon::before {
  top: -1rem; }

.navigation__button:hover .navigation__icon::after {
  top: 1rem; }

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent; }

.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(135deg); }

.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg); }

.section-about {
  background-color: #f7f7f7;
  padding: 25rem 0;
  margin-top: -20vh; }
  @media only screen and (max-width: 56.25em) {
    .section-about {
      padding: 20rem 0; } }

.section-features {
  padding: 5rem 0;
  background-image: linear-gradient(to right bottom, rgba(84, 170, 1, 0.6), rgba(40, 180, 133, 0.8)), url(../public/img/bpl-4.jpg);
  background-size: cover;
  margin-top: -15rem; }
  @media only screen and (max-width: 56.25em) {
    .section-features {
      padding: 5rem 0 5rem 0; } }

.section-stories {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(../public/img/bpl-5.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .8;
  overflow: hidden; }
  .bg-image__content {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.about-us__heading {
  border-bottom: 1.2rem solid #54aa01; }

.about-us__image-1 {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../public/img/bpl-6-large.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .about-us__image-1-border {
    margin-bottom: 5rem; }

.about-us__image-1-text {
  width: 80%;
  margin-top: 15rem;
  text-align: center; }
  @media only screen and (max-width: 37.5em) {
    .about-us__image-1-text {
      margin-top: 0rem; } }

.about-us__content {
  margin: 0 5rem;
  height: 100vh;
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(../public/img/logo-white-medium.png);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  @media only screen and (max-width: 56.25em) {
    .about-us__content {
      background-attachment: scroll;
      height: 80vh; } }
  @media only screen and (max-width: 37.5em) {
    .about-us__content {
      background-image: none;
      height: initial; } }

.about-us__aim-and-objective > div {
  font-size: 2rem;
  color: #000;
  font-weight: 700;
  margin-left: 2rem;
  margin-bottom: 2rem; }

.about-us__list-item {
  font-size: 2rem; }
  .about-us__list-item > ul {
    list-style: none; }
  .about-us__list-item > ul > li::before {
    content: "\2022";
    color: #F68505;
    display: inline-block;
    width: 1em;
    margin-left: -1em; }

.about-us__item {
  margin-bottom: 2rem; }

.about-us__image-2 {
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 1rem; }
  @media only screen and (max-width: 75em) {
    .about-us__image-2 {
      width: 50rem; } }
  @media only screen and (max-width: 37.5em) {
    .about-us__image-2 {
      width: 30rem; } }
  @media only screen and (max-width: 16.25em) {
    .about-us__image-2 {
      width: 100%; } }

.mental-h__hero-bg {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../public/img/bpl-14.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  padding: 30px; }
  @media only screen and (max-width: 56.25em) {
    .mental-h__hero-bg {
      justify-content: center; } }
  @media only screen and (max-width: 37.5em) {
    .mental-h__hero-bg {
      height: 70vh; } }

.mental-h__heading {
  width: 700px;
  margin-left: 20%;
  margin-bottom: 50px; }
  @media only screen and (max-width: 56.25em) {
    .mental-h__heading {
      margin-left: 0; } }
  .mental-h__heading h1 {
    font-size: 35px;
    font-weight: bold;
    color: white;
    line-height: 46px; }
    @media only screen and (max-width: 56.25em) {
      .mental-h__heading h1 {
        font-size: 30px;
        line-height: 41px; } }

.our-services {
  padding-top: 3rem; }
  .our-services__heading {
    border-bottom: 1.2rem solid #54aa01;
    margin-bottom: 1rem; }
  .our-services__row {
    display: flex;
    justify-content: center;
    background-color: rgba(84, 170, 1, 0.2);
    padding-top: 8rem; }
    .our-services__row:last-child {
      padding-top: 4rem;
      padding-bottom: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .our-services__row:last-child {
          padding-top: 1rem; } }
    @media only screen and (max-width: 56.25em) {
      .our-services__row {
        flex-direction: column;
        align-items: center;
        width: 100%; } }
    @media only screen and (max-width: 37.5em) {
      .our-services__row {
        width: 100%; } }
  .our-services__text-box {
    display: none;
    width: 94%;
    margin-top: -1rem;
    margin-bottom: 2rem; }
    @media only screen and (max-width: 25em) {
      .our-services__text-box {
        display: block; } }

.page-contact-us__heading {
  border-bottom: 1.2rem solid #54aa01; }

.page-contact-us__header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(../public/img/bpl-12.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin-bottom: 1rem; }
  @media only screen and (max-width: 56.25em) {
    .page-contact-us__header {
      height: 70vh; } }
  @media only screen and (max-width: 37.5em) {
    .page-contact-us__header {
      height: 40vh; } }
  .page-contact-us__header-border {
    margin-bottom: 5rem; }

.page-contact-us__header-text {
  font-size: 3.5rem;
  font-weight: 800;
  color: #fff;
  margin-top: 3rem;
  animation-name: moveInBottom;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-timing-function: ease-out; }

.page-contact-us__container {
  width: 90%;
  margin: 5rem auto; }

.page-contact-us__row {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 56.25em) {
    .page-contact-us__row {
      flex-direction: column; } }

.page-contact-us__form-info {
  flex: 0 1 60%; }
  .page-contact-us__form-info h3 {
    font-size: 2.5rem;
    font-weight: 900; }
  @media only screen and (max-width: 37.5em) {
    .page-contact-us__form-info {
      flex: 0 1 100%; } }

.page-contact-us__form {
  font-size: 1.8rem; }

.page-contact-us__form-row {
  margin-bottom: 1rem; }
  .page-contact-us__form-row label {
    display: block;
    font-weight: 600;
    color: #ff7730; }
    .page-contact-us__form-row label span {
      color: red; }
  .page-contact-us__form-row input {
    border: 3px solid #000;
    width: 60%;
    height: 4rem;
    font-size: 1.7rem;
    padding-left: 8px;
    outline: none; }
    @media only screen and (max-width: 37.5em) {
      .page-contact-us__form-row input {
        width: 80%;
        height: 3.5rem; } }
    .page-contact-us__form-row input:focus {
      border: 2px solid #54aa01; }
  .page-contact-us__form-row textarea {
    border: 3px solid #000;
    width: 80%;
    padding-left: 0.8rem;
    padding-top: 0.8rem;
    font-size: 1.7rem;
    outline: none;
    resize: none; }
    @media only screen and (max-width: 37.5em) {
      .page-contact-us__form-row textarea {
        width: 90%; } }
    .page-contact-us__form-row textarea:focus {
      border: 2px solid #54aa01; }
  .page-contact-us__form-row button {
    text-align: center;
    width: 8rem;
    height: 3.5rem;
    border-radius: 5px;
    border-color: #fff;
    cursor: pointer;
    background-color: #54aa01;
    color: #fff; }
    .page-contact-us__form-row button:hover, .page-contact-us__form-row button:active {
      background-color: #fff;
      color: #54aa01;
      font-weight: 700;
      border-color: #54aa01; }
  .page-contact-us__form-row p {
    font-size: 1.3rem;
    color: red;
    margin-bottom: 0.5rem; }

.page-contact-us__location-info {
  flex: 0 1 30%; }
  @media only screen and (max-width: 37.5em) {
    .page-contact-us__location-info {
      margin-top: 3rem; } }
  .page-contact-us__location-info h3 {
    font-size: 2.5rem;
    font-weight: 900; }
  .page-contact-us__location-info h4 {
    font-size: 2rem;
    font-weight: 900; }

.map-background {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(../public/img/bpl-13.jpg);
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.mapouter {
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%; }

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%; }
